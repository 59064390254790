import React, { useState, useEffect } from "react";
import "./incidents.scss";
import { FormControl, Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import {
  getDateRange,
  getFormatedDate,
  downloadFullPDF,
  getDayNameAndDate,
} from "../../../../utils/helper";
import {
  TIMEPERIOD,
  associatedIncidentType1,
  TOP_FIVE_PART_INJURED,
  CORRENT_OPEN_CASE,
  COLORS,
  USER_WITH_MOST_INCIDENT,
  USER_WITH_MOST_ACTION,
  ORG_ID,
} from "../../../../constant/constant";
import TopSection1 from "../../../../library/topSection/topSection";
import InjuredPart from "../../../case/common/personalInjury/injuredPart";
import LineChart from "../../../../library/custom/charts/linechart";
import BarChart from "../../../../library/custom/charts/barchart";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail } from "../../../../constant/routeContant";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import Loader from "../../../../library/common/Loader";
import Buttons from "../../../../library/custom/button/Button";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { useThemeContext } from "../../../../context";

const Incidents = (props) => {
  const [incidentType, setIncidentType] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timePeriod, setTimePeriod] = useState(2);
  const navigate = useNavigate();
  const [divisionId, setDivisionId] = useState("AllDivision");
  const [divisions, setDivisions] = useState([]);
  const [locationOfRecord, setLocationOfRecord] = useState("AllLocation");
  const [locationOfRecordData, setLocationOfRecordData] = useState();
  const [trir, setTrir] = useState(null);
  const [trirResultFinal, settrirResultFinal] = useState(null);
  const valOshacount = props?.IncidentReportData?.data?.result?.isOshaCount;

  const { mode } = useThemeContext();

  useEffect(() => {
    if (props?.backDetail?.backpageInc === "backpageInc") {
      setIncidentType(props?.backDetail?.apiData?.currentincidenttype);
      setDivisionId(
        props?.backDetail?.apiData?.divisionId === 0
          ? "AllDivision"
          : props?.backDetail?.apiData?.divisionId
      );
      setStartDate(props?.backDetail?.apiData?.startDate);
      setEndDate(props?.backDetail?.apiData?.endDate);
      setTimePeriod(props?.backDetail?.apiData?.timePeriod);
    }
    let flags = [6];

    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: flags,
      productId: PRODUCT_ID(),
    };
    props?.getDivisionData(getDropdownDataKeyValueData);
  }, [props?.backDetail]);
  const handleIncidentTypeChange = (e) => {
    settrirResultFinal("");
    setTrir("");
    setIncidentType(e?.target?.value);
    //  const value = e?.target?.value;
    const dateRange = getDateRange(
      timePeriod === 1
        ? 7
        : timePeriod === 2
        ? 30
        : timePeriod === 3
        ? 60
        : timePeriod === 4
        ? 90
        : null
    );

    setStartDate(getFormatedDate(dateRange?.startDate));
    setEndDate(getFormatedDate(dateRange?.endDate));
    setTimePeriod(2);
  };

  const handleTimePeriodChange = (e) => {
    const selectedPeriod = e?.target?.value;
    setTimePeriod(selectedPeriod);
    const dateRange = getDateRange(
      selectedPeriod === 1
        ? 7
        : selectedPeriod === 2
        ? 30
        : selectedPeriod === 3
        ? 60
        : selectedPeriod === 4
        ? 90
        : null
    );

    setStartDate(getFormatedDate(dateRange?.startDate));
    setEndDate(getFormatedDate(dateRange?.endDate));
    settrirResultFinal("");
    setTrir("");
  };

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
    settrirResultFinal("");
    setTrir("");
  };
  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
    settrirResultFinal("");
    setTrir("");
  };

  useEffect(() => {
    if (
      incidentType &&
      startDate &&
      endDate &&
      divisionId &&
      locationOfRecord
    ) {
      const data = {
        incidentType: incidentType > 0 ? incidentType : 0,
        fromDate: getFormatedDate(startDate),
        toDate: getFormatedDate(endDate),
        divisionId: divisionId === "AllDivision" ? 0 : divisionId,
        locationId: locationOfRecord === "AllLocation" ? 0 : locationOfRecord,
      };
      props?.getIncidentReport(data);
    }
  }, [incidentType, startDate, endDate, divisionId, locationOfRecord]);

  const currentOpenCasesList =
    props?.IncidentReportData?.data?.result?.currentOpenCasesList;

  const incidentData = props?.IncidentReportData?.data?.result?.incidentList;

  const userWithIncidentListData =
    props?.IncidentReportData?.data?.result?.userWithIncidentList;
  const userWithActionListData =
    props?.IncidentReportData?.data?.result?.userWithActionList;

  const processIncidentData = (data, startDate, endDate) => {
    const result = {};

    // Convert start and end dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Initialize result object with dates in the range
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      const dateString = `${(d.getMonth() + 1).toString().padStart(2, "0")}-${d
        .getDate()
        .toString()
        .padStart(2, "0")}-${d.getFullYear()}`; // Format as MM-DD-YYYY
      result[dateString] = {
        date: dateString,
        VehicleAccidentCount: 0,
        PersonalInjuryCount: 0,
        StolenPropertyCount: 0,
        NearMissCount: 0,
        UtilityDamageCount: 0,
        TotalIncidentCount: 0, // Added total count
      };
    }
    data?.forEach((incident) => {
      const { createdOn, incType } = incident;
      // Convert createdOn to YYYY-MM-DD format for correct parsing
      const [month, day, year] = createdOn?.split("-");
      const incidentDate = new Date(`${year}-${month}-${day}`);
      const dateString = `${(incidentDate?.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${incidentDate
        .getDate()
        .toString()
        .padStart(2, "0")}-${incidentDate.getFullYear()}`; // Format as MM-DD-YYYY

      // Check if the incident date is within the specified range

      if (result[dateString]) {
        switch (incType.toLowerCase()) {
          case "vehicle accident":
            result[dateString].VehicleAccidentCount += 1;
            break;
          case "personal injury":
            result[dateString].PersonalInjuryCount += 1;
            break;
          case "stolen property":
            result[dateString].StolenPropertyCount += 1;
            break;
          case "near miss":
            result[dateString].NearMissCount += 1;
            break;
          case "utility damage":
            result[dateString].UtilityDamageCount += 1;
            break;
          default:
            console.warn(`Unknown incident type: ${incType}`);
            break;
        }
        // Increment total incidents count
        result[dateString].TotalIncidentCount += 1;
      }
    });

    return Object.values(result);
  };

  const [processedData, setProcessedData] = React.useState([]);

  useEffect(() => {
    if (props?.IncidentReportData?.data?.result) {
      const data = processIncidentData(
        props?.IncidentReportData?.data?.result?.incidentList,
        startDate,
        endDate
      );

      setProcessedData(data);
    }
  }, [props?.IncidentReportData?.data?.result]);

  const formatDate = (date) => {
    const day = date?.getDate()?.toString()?.padStart(2, "0");
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const getDaysDifference = (startDate, endDate) => {
    return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates?.push(new Date(currentDate));
      currentDate?.setDate(currentDate?.getDate() + 1);
    }

    return dates;
  };

  const aggregateData = (data) => {
    const aggregated = {};
    const startDatee = new Date(data[0]?.date);
    const endDatee = new Date(data[data.length - 1]?.date);

    const daysDifference = getDaysDifference(startDatee, endDatee);

    const isDaily = daysDifference <= 60;
    const allDates = generateDateRange(startDatee, endDatee);

    allDates.forEach((date) => {
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString().padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;
      aggregated[key] = {
        year,
        month,
        day: isDaily ? day : undefined,
        fullDate: isDaily ? formatDate(date) : `${month}-${year}`,
        NearMissCount: 0,
        PersonalInjuryCount: 0,
        StolenPropertyCount: 0,
        UtilityDamageCount: 0,
        VehicleAccidentCount: 0,
      };
    });

    data.forEach((entry) => {
      const date = new Date(entry?.date);
      const year = date?.getFullYear();
      const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
      const day = date?.getDate()?.toString()?.padStart(2, "0");
      const key = isDaily ? `${year}-${month}-${day}` : `${year}-${month}`;

      if (aggregated[key]) {
        aggregated[key].NearMissCount += entry.NearMissCount || 0;
        aggregated[key].PersonalInjuryCount += entry.PersonalInjuryCount || 0;
        aggregated[key].StolenPropertyCount += entry.StolenPropertyCount || 0;
        aggregated[key].UtilityDamageCount += entry.UtilityDamageCount || 0;
        aggregated[key].VehicleAccidentCount += entry.VehicleAccidentCount || 0;
      }
    });

    return Object.values(aggregated);
  };

  const generateLineChartData = (data) => {
    const aggregatedData = aggregateData(data);

    return {
      labels: aggregatedData.map((entry) => entry.fullDate),
      datasets: [
        {
          label: "Near Miss",
          data: aggregatedData.map((entry) => entry.NearMissCount || 0),
          borderColor: COLORS.BrightAqua,
          backgroundColor: COLORS.BrightAqua,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Personal Injury",
          data: aggregatedData.map((entry) => entry.PersonalInjuryCount || 0),
          borderColor: COLORS.VeryDardBlue,
          backgroundColor: COLORS.VeryDardBlue,
          borderWidth: 2,
          fill: false,
        },

        {
          label: "Stolen Property",
          data: aggregatedData.map((entry) => entry.StolenPropertyCount || 0),
          borderColor: COLORS.blue,
          backgroundColor: COLORS.blue,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Utility Damage",
          data: aggregatedData.map((entry) => entry.UtilityDamageCount || 0),
          borderColor: COLORS.LightGreen,
          backgroundColor: COLORS.LightGreen,
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Vehicle Accident",
          data: aggregatedData.map((entry) => entry.VehicleAccidentCount || 0),
          borderColor: COLORS.Pink,
          backgroundColor: COLORS.Pink,
          borderWidth: 2,
          fill: false,
        },
      ],
    };
  };

  const linechartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: mode === "dark" ? "#fff" : "#333333",
          font: {
            family: "Lato",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? "#fff" : "#333333",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: mode === "dark" ? "#fff" : "#333333",
        },
      },
    },
  };

  // line chart code end
  const fulldata = incidentData?.filter((val) => {
    return val;
  });
  const personalenjurtdata = incidentData?.filter((val) => {
    return val?.incidentTypeId === 1;
  });
  const VehicleAccidentdata = incidentData?.filter((val) => {
    return val?.incidentTypeId === 2;
  });
  const StolenPropertydata = incidentData?.filter((val) => {
    return val?.incidentTypeId === 3;
  });
  const UtilityDamagedata = incidentData?.filter((val) => {
    return val?.incidentTypeId === 4;
  });
  const NearMissdata = incidentData?.filter((val) => {
    return val?.incidentTypeId === 5;
  });
  function findMaxDuplicateObject(data) {
    let objectCountMap = new Map();

    data?.forEach((obj) => {
      let key = JSON.stringify(obj);

      if (objectCountMap.has(key)) {
        objectCountMap.set(key, objectCountMap.get(key) + 1);
      } else {
        objectCountMap.set(key, 1);
      }
    });

    let maxCount = 0;
    let maxKey = null;
    objectCountMap?.forEach((count, key) => {
      if (count > maxCount) {
        maxCount = count;
        maxKey = key;
      }
    });
    return JSON.parse(maxKey);
  }

  const InjTypeRec = findMaxDuplicateObject(
    props?.IncidentReportData?.data?.result?.bodyPartInjuriesList
  );

  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    //  maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          autoSkip: false, // Prevents skipping of labels
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        stacked: false,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };

  // most common day of week
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 7;
  const getPaginatedData = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return props?.IncidentReportData?.data?.result?.dayOfTheWeekList?.slice(
      start,
      end
    );
  };
  // code start days========================================================================

  const injuryCountByDay = {
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  };

  props?.IncidentReportData?.data?.result?.dayOfTheWeekList.forEach((entry) => {
    const date = new Date(entry.date);
    const dayName = date.toLocaleString("en-US", { weekday: "long" });
    injuryCountByDay[dayName] += entry.numberOfInjuries;
  });

  // Extract data for the chart
  const days = Object.keys(injuryCountByDay);
  const counts = Object.values(injuryCountByDay);

  // end code ===========================================================================================
  const paginatedData = getPaginatedData();
  const StartEndDate =
    props?.IncidentReportData?.data?.result?.dayOfTheWeekList?.map(
      (item) => item?.date
    );

  const IncidentDay = {
    // labels: paginatedData?.map((item) => getDayNameAndDate(item?.date)),
    labels: days,
    datasets: [
      {
        label: "Number of Injuries",
        //  data: paginatedData?.map((item) => item?.numberOfInjuries),
        data: counts,
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const projectWithMostIncident = {
    labels:
      props?.IncidentReportData?.data?.result?.projectWithIncidentList?.map(
        (itm) => itm.name
      ),
    datasets: [
      {
        label: "Number of incidents",
        data: props?.IncidentReportData?.data?.result?.projectWithIncidentList?.map(
          (item) => item?.numberOfIncident
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };
  const vehicleWithMostIncident = {
    labels:
      props?.IncidentReportData?.data?.result?.vehicleWithIncidentList?.map(
        (itm) => itm?.makeAndModel
      ),
    datasets: [
      {
        label: "vehicle Most Incidents",
        data: props?.IncidentReportData?.data?.result?.vehicleWithIncidentList?.map(
          (item) => item?.numberOfIncident
        ),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };
  const drilldownOpenCases = (action, statusId) => {
    const data = {
      startDate: getFormatedDate(startDate),
      endDate: getFormatedDate(endDate),
      timePeriod: timePeriod,
      incidentType: incidentType,
      divisionId: divisionId === "AllDivision" ? 0 : divisionId,
      locationId: locationOfRecord === "AllLocation" ? 0 : locationOfRecord,
      currentincidenttype: incidentType,
    };

    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
      state: {
        page: action,
        apiData: data,
        iscase: true,
        statusId: statusId,
        tab: props?.tab,
      },
    });
  };

  const drilldownLineChart = (action, reportDetails) => {
    const data = {
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      timePeriod: timePeriod,
      incidentType: reportDetails,
      divisionId: divisionId === "AllDivision" ? 0 : divisionId,
      locationId: locationOfRecord === "AllLocation" ? 0 : locationOfRecord,
      currentincidenttype: incidentType,
    };
    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
      state: {
        page: action,
        apiData: data,
        reportDetails: reportDetails,
        isIncident: true,
        tab: props?.tab,
      },
    });
  };
  const drilldownHandler = (action, reportDetails, trendData) => {
    const data = {
      startDate: startDate ? getFormatedDate(startDate) : "",
      endDate: endDate ? getFormatedDate(endDate) : "",
      timePeriod: timePeriod,
      incidentType: incidentType,
      divisionId: divisionId === "AllDivision" ? 0 : divisionId,
      locationId: locationOfRecord === "AllLocation" ? 0 : locationOfRecord,
      currentincidenttype: incidentType,
    };

    if (action === "most_injured_body_part") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
        },
      });
    } else if (action === "most_common_injury") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          // page: "most_common_injury",
          tab: props?.tab,
        },
      });
    } else if (action === "most_common_day_week") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
          trendPassData: trendData,
        },
      });
    } else if (action === "project_most_incident") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
        },
      });
    } else if (action === "vehicle_most_incident") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
        },
      });
    } else if (action === "user_most_incident") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
        },
      });
    } else if (action === "user_most_action") {
      navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
        state: {
          page: action,
          apiData: data,
          reportDetails: reportDetails,
          tab: props?.tab,
        },
      });
    }
  };

  function calculateAverageClosureTime(cases) {
    let totalClosureTime = 0;
    let count = 0;

    cases?.forEach((c) => {
      const createdDate = new Date(c.createdOn);
      const closedDate = new Date(c.closedOn);

      if (createdDate && closedDate) {
        const closureTime = closedDate - createdDate;
        const closureTimeInDays = closureTime / (1000 * 60 * 60 * 24);
        totalClosureTime += closureTimeInDays;
        count++;
      }
    });

    const averageClosureTime = totalClosureTime / count;
    return averageClosureTime;
  }
  // const averageTimeToClose = calculateAverageClosureTime(
  //   props?.IncidentReportData?.data?.result?.closedCaseList
  // );

  // const handlePrevious = () => {
  //   if (currentPage > 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handleNext = () => {
  //   if (
  //     (currentPage + 1) * ITEMS_PER_PAGE <
  //     props?.IncidentReportData?.data?.result?.dayOfTheWeekList?.length
  //   ) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  const handleClick = (evt, label) => {
    let dayName;
    switch (label) {
      case "Monday":
        dayName = 1;
        break;
      case "Tuesday":
        dayName = 2;
        break;
      case "Wednesday":
        dayName = 3;
        break;
      case "Thursday":
        dayName = 4;
        break;
      case "Friday":
        dayName = 5;
        break;
      case "Saturday":
        dayName = 6;
        break;
      case "Sunday":
        dayName = 0;
        break;
      default:
        dayName = "";
    }
    const data = {
      startDate: getFormatedDate(startDate),
      endDate: getFormatedDate(endDate),
      timePeriod: timePeriod,
      incidentType: incidentType,
      divisionId: divisionId === "AllDivision" ? 0 : divisionId,
      locationId: locationOfRecord === "AllLocation" ? 0 : locationOfRecord,
      currentincidenttype: incidentType,
      Day: dayName,
    };
    // const date = label.split(", ");

    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail, {
      state: {
        page: "most_common_day_week",
        apiData: data,
        tab: props?.tab,
      },
    });
    // need to get date which one convert in data
  };
  const optionsDay = {
    indexAxis: "x",
    onClick: function (evt, element) {
      if (element.length > 0) {
        const elementIndex = element[0].index;
        const clickedLabel = IncidentDay.labels[elementIndex];
        handleClick(evt, clickedLabel);
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    //  maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          autoSkip: false, // Prevents skipping of labels
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
      y: {
        stacked: false,
        ticks: {
          color: mode === "dark" ? COLORS.White : COLORS.Dune,
        },
      },
    },
  };
  useEffect(() => {
    props?.divisions?.unshift({ key: "AllDivision", value: "All Divisions" });
    setDivisions(props?.divisions);
  }, [props?.divisions]);

  useEffect(() => {
    props?.locationOfRecordData?.unshift({
      key: "AllLocation",
      value: "All Establishments",
    });
    setLocationOfRecordData(props?.locationOfRecordData);
  }, [props?.locationOfRecordData]);
  const handleDivisionChange = (e) => {
    setDivisionId(e?.target?.value);
  };
  const handleLocationOfRecordChange = (e) => {
    setLocationOfRecord(e?.target?.value);
  };
  const onchangeHandleTrir = (event) => {
    const re = /^[0-9.\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      var count = (event.target.value.match(/\./g) || []).length;
      if (count > 1) {
        var minval = event.target.value.replace(/\.+$/, "");
      }
      setTrir(event.target.value);
      if (minval) {
        setTrir("");
      }
    }
  };

  const calculateTrir = () => {
    const trirResult = (valOshacount * 200000) / trir;
    settrirResultFinal(trirResult.toFixed(2));
  };

  return (
    <>
      <div id="pdf-loader" class="loader pdf-loader">
        <Loader />
      </div>
      <div className="overviewInc overviewInsReport" id="pdf-content">
        {incidentType === -1 ||
        incidentType === 1 ||
        incidentType === "" ||
        !incidentType ? (
          <>
            {" "}
            <div id="first-page">
              <Grid container>
                <Grid item md={12}>
                  <h1>Overview</h1>
                </Grid>
                {incidentType && timePeriod && (
                  <Grid item md={12} xs={12}>
                    <Button
                      onClick={() =>
                        downloadFullPDF("pdf-content", "incident.pdf", [
                          "first-page",
                          "second-page",
                        ])
                      }
                      variant="contained"
                      startIcon={<DownloadIcon className="downloadIcon" />}
                      className="downloadpdf sw marginRemove float_right"
                    >
                      Download as PDF
                    </Button>
                  </Grid>
                )}

                <Grid container mt={0} spacing={2}>
                  <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                      <FormLabels label="Incident Type" isRequired={true} />
                      <SelectMenu
                        listData={associatedIncidentType1}
                        value={incidentType || ""}
                        onchangehandler={(e) => handleIncidentTypeChange(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {incidentType && (
                  <>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={false} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={divisions}
                            value={divisionId}
                            onchangehandler={(e) => handleDivisionChange(e)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels
                            label="Establishment"
                            isRequired={false}
                          />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={locationOfRecordData ?? []}
                            value={locationOfRecord ? locationOfRecord : null}
                            onchangehandler={(e) =>
                              handleLocationOfRecordChange(e)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      {incidentType && (
                        <>
                          <Grid md={4} xs={12} item>
                            <FormControl fullWidth>
                              <FormLabels
                                label="Time Period"
                                isRequired={true}
                              />
                              <SelectMenu
                                listData={TIMEPERIOD}
                                value={timePeriod}
                                onchangehandler={(e) =>
                                  handleTimePeriodChange(e)
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            {incidentType && timePeriod === 5 ? (
                              <>
                                <Grid item container spacing={2} mb={2} mt={0}>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"From"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      value={startDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeStartdatePicker(e)
                                      }
                                    />
                                  </Grid>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"To"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      minDate={startDate}
                                      value={endDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeEnddatePicker(e)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              {incidentType && (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid container md={12}>
                      <Grid md={9}>
                        <LineChart
                          labels={
                            generateLineChartData(processedData || []).labels
                          }
                          datasets={
                            generateLineChartData(processedData || []).datasets
                          }
                          options={linechartoptions}
                          height="auto"
                        />
                      </Grid>
                      <Grid md={3} xs={12} className="totalincbox">
                        <Grid
                          container
                          className="incidentreportboxs cursor"
                          onClick={
                            incidentType === -1
                              ? () =>
                                  drilldownLineChart("report_totalIncidents", 0)
                              : incidentType === 1
                              ? () =>
                                  drilldownLineChart("report_personaInjury", 1)
                              : incidentType === 2
                              ? () =>
                                  drilldownLineChart(
                                    "report_VehicleAccident",
                                    2
                                  )
                              : incidentType === 3
                              ? () =>
                                  drilldownLineChart("report_StolenProperty", 3)
                              : incidentType === 4
                              ? () =>
                                  drilldownLineChart("report_UtilityDamage", 4)
                              : incidentType === 5
                              ? () => drilldownLineChart("report_NearMiss", 5)
                              : ""
                          }
                        >
                          <Grid item md={12} className="commonBoxShodow">
                            <div className={"lessoreportCardBody"}>
                              <div className="text">
                                {incidentType === -1
                                  ? "Total Incidents"
                                  : incidentType === 1
                                  ? "Personal Injury"
                                  : incidentType === 2
                                  ? "Vehicle Accident"
                                  : incidentType === 3
                                  ? "Stolen Property"
                                  : incidentType === 4
                                  ? "Utility Damage"
                                  : incidentType === 5
                                  ? "Near Miss"
                                  : ""}
                              </div>
                              <div
                                className={`pernumber ${
                                  incidentType === -1
                                    ? "total"
                                    : incidentType === 1
                                    ? "per"
                                    : incidentType === 2
                                    ? "veh"
                                    : incidentType === 3
                                    ? "stolen"
                                    : incidentType === 4
                                    ? "utility"
                                    : incidentType === 5
                                    ? "nearmiss"
                                    : ""
                                }`}
                              >
                                {incidentType === -1
                                  ? fulldata?.length
                                  : incidentType === 1
                                  ? personalenjurtdata?.length
                                  : incidentType === 2
                                  ? VehicleAccidentdata?.length
                                  : incidentType === 3
                                  ? StolenPropertydata?.length
                                  : incidentType === 4
                                  ? UtilityDamagedata?.length
                                  : incidentType === 5
                                  ? NearMissdata?.length
                                  : ""}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {incidentType === -1 ? (
                        <>
                          <Grid container className="boxes" mt={2}>
                            <Grid
                              item
                              md={2}
                              xs={12}
                              className="incidentreportboxs cursor"
                              onClick={() =>
                                drilldownLineChart("report_personaInjury", 1)
                              }
                            >
                              <Grid className="commonBoxShodow box">
                                <div className={"lessoreportCardBody"}>
                                  <div className="text">Personal Injury</div>
                                  <div className="pernumber per">
                                    {personalenjurtdata?.length}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              className="incidentreportboxs cursor"
                              xs={12}
                              onClick={() =>
                                drilldownLineChart("report_VehicleAccident", 2)
                              }
                            >
                              <Grid className="commonBoxShodow box">
                                <div className={"lessoreportCardBody"}>
                                  <div className="text">Vehicle Accident</div>
                                  <div className="pernumber veh">
                                    {VehicleAccidentdata?.length}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={12}
                              className="incidentreportboxs cursor"
                              onClick={() =>
                                drilldownLineChart("report_StolenProperty", 3)
                              }
                            >
                              <Grid className="commonBoxShodow box">
                                <div className={"lessoreportCardBody"}>
                                  <div className="text">Stolen Property</div>
                                  <div className="pernumber stolen">
                                    {StolenPropertydata?.length}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={12}
                              className="incidentreportboxs cursor"
                              onClick={() =>
                                drilldownLineChart("report_UtilityDamage", 4)
                              }
                            >
                              <Grid className="commonBoxShodow box">
                                <div className={"lessoreportCardBody"}>
                                  <div className="text">Utility Damage</div>
                                  <div className="pernumber utility">
                                    {UtilityDamagedata?.length}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={12}
                              className="incidentreportboxs cursor"
                              onClick={() =>
                                drilldownLineChart("report_NearMiss", 5)
                              }
                            >
                              <Grid className="commonBoxShodow box">
                                <div className={"lessoreportCardBody"}>
                                  <div className="text">Near Miss</div>
                                  <div className="pernumber nearmiss">
                                    {NearMissdata?.length}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  {incidentType === 1 || incidentType === -1 ? (
                    <>
                      <Grid container spacing={2} mt={2}>
                        <Grid item md={8} xs={12}>
                          <Grid className="commonBoxShodow cursor">
                            <h2 className="titletop">
                              Personal Injury Analysis
                            </h2>
                            <Grid container className="PersonalenjAnalysis">
                              <Grid
                                md={4}
                                sm={4}
                                xs={12}
                                item
                                className="incidentreportBodypart injuredpdf"
                              >
                                <InjuredPart
                                  bodyPartInj={[InjTypeRec?.bpiType]}
                                />
                              </Grid>
                              <Grid md={8} sm={8} xs={12} item>
                                <Grid className="personalinjurysection">
                                  <Grid
                                    className="commonBoxShodow commonbox50"
                                    onClick={() =>
                                      drilldownHandler(
                                        "most_injured_body_part",
                                        1
                                      )
                                    }
                                  >
                                    <div
                                      className={"lessoreportCardBody cursor"}
                                    >
                                      <div className="text">
                                        Most Injured Body Part
                                      </div>
                                      <div
                                        className="number"
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {InjTypeRec?.bpiType
                                          ? InjTypeRec?.bpiType
                                          : "NA"}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    className="commonBoxShodow commonbox50"
                                    onClick={() =>
                                      drilldownHandler("most_common_injury", 2)
                                    }
                                    md={8}
                                  >
                                    <div
                                      className={"lessoreportCardBody cursor"}
                                    >
                                      <div className="text">
                                        Most Common Injury
                                      </div>
                                      <div
                                        className="inj"
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {props?.IncidentReportData?.data?.result
                                          ?.commonInjuryTypeList[0]?.injury
                                          ? props?.IncidentReportData?.data
                                              ?.result?.commonInjuryTypeList[0]
                                              ?.injury
                                          : "NA"}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <div
                            className="inc_inj cursor"
                            onClick={() => {
                              if (
                                props?.IncidentReportData?.data?.result
                                  ?.bodyPartInjuriesList?.length > 0
                              ) {
                                drilldownHandler("most_injured_body_part", 1);
                              }
                            }}
                          >
                            <TopSection1
                              title={"Top 5 Body Parts Injured"}
                              column={TOP_FIVE_PART_INJURED}
                              rows={
                                props?.IncidentReportData?.data?.result
                                  ?.bodyPartInjuriesList
                              }
                              topsection={"INJURED"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            {incidentType && (
              <div id="second-page">
                <>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={12} mt={2}>
                      <Grid className="commonBoxShodow cursor">
                        <h2 className="titletop">
                          Most common day of the week
                        </h2>
                        <div className="date-range">
                          Date Range{" "}
                          {/* <span>
                            {paginatedData ? paginatedData[0]?.date : ""} {"-"}{" "}
                            {paginatedData
                              ? paginatedData[paginatedData?.length - 1]?.date
                              : ""}
                          </span> */}
                          <span>
                            {StartEndDate && StartEndDate[0]} {"-"}{" "}
                            {StartEndDate &&
                              StartEndDate[StartEndDate.length - 1]}
                          </span>
                        </div>
                        <div
                        // onClick={() =>
                        //   drilldownHandler(
                        //     "most_common_day_week",
                        //     "",
                        //     props?.IncidentReportData?.data?.result
                        //       ?.dayOfTheWeekList
                        //   )
                        // }
                        >
                          <BarChart
                            labels={IncidentDay?.labels}
                            datasets={IncidentDay?.datasets}
                            options={optionsDay}
                            height="auto"
                          />
                        </div>
                        {/* <div className="pagination-controls hideinpdf">
                          <Buttons
                            label={"Previous"}
                            varientContained={true}
                            onClick={handlePrevious}
                            disabled={currentPage === 0}
                          />

                          <Buttons
                            label={"Next"}
                            varientContained={true}
                            onClick={handleNext}
                            disabled={
                              (currentPage + 1) * ITEMS_PER_PAGE >=
                              props?.IncidentReportData?.data?.result
                                ?.dayOfTheWeekList?.length
                            }
                          />
                        </div> */}
                      </Grid>
                      {incidentType === -1 || incidentType !== 2 ? (
                        <>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            onClick={() => {
                              if (
                                projectWithMostIncident.datasets?.length > 0
                              ) {
                                drilldownHandler("project_most_incident", 4);
                              }
                            }}
                            mt={2}
                          >
                            <Grid className="commonBoxShodow cursor">
                              <h2 className="titletop">
                                Projects with the Most Incidents
                              </h2>
                              <BarChart
                                labels={projectWithMostIncident.labels}
                                datasets={projectWithMostIncident.datasets}
                                options={options}
                                height="auto"
                              />
                            </Grid>
                          </Grid>

                          {incidentType === 1 && (
                            <Grid
                              md={12}
                              className="totalhour commonBoxShodow"
                              gap={3}
                              item
                              mt={3}
                              spacing={3}
                            >
                              <Grid md={8} xs={12}>
                                <FormControl fullWidth>
                                  <FormLabels
                                    label={"Total Hours Worked"}
                                    isRequired={false}
                                  />
                                  <InputField
                                    fullWidth={true}
                                    disabled={false}
                                    type="text"
                                    placeholder="Please enter here..."
                                    value={trir}
                                    onChange={(event, value) =>
                                      onchangeHandleTrir(event)
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item textAlign="center" mt={3}>
                                <Buttons
                                  disabled={false}
                                  varientContained={true}
                                  label={"Calculate"}
                                  onClick={calculateTrir}
                                />
                              </Grid>
                              <div className="trir">
                                <p>
                                  TRIR = (Number of recordable incidents) X
                                  200,000 / (Total number of hours worked)
                                </p>{" "}
                              </div>
                              <div>
                                {trirResultFinal &&
                                  trirResultFinal !== "Infinity" && (
                                    <div className="trir h1">
                                      <h1>TRIR ={trirResultFinal}</h1>
                                    </div>
                                  )}
                              </div>
                            </Grid>
                          )}
                        </>
                      ) : (
                        <Grid item md={8} xs={12}>
                          <Grid
                            className="commonBoxShodow cursor"
                            onClick={() => {
                              if (
                                vehicleWithMostIncident?.datasets?.length > 0
                              ) {
                                drilldownHandler("vehicle_most_incident", 6);
                              }
                            }}
                          >
                            <h2 className="titletop">
                              Vehicles with the Most Incidents
                            </h2>
                            <BarChart
                              labels={vehicleWithMostIncident?.labels}
                              datasets={vehicleWithMostIncident?.datasets}
                              options={options}
                              height="auto"
                            />
                          </Grid>
                        </Grid>
                      )}
                      {incidentType === -1 ? (
                        <>
                          <Grid
                            className="commonBoxShodow cursor"
                            onClick={() => {
                              if (
                                vehicleWithMostIncident.datasets?.length > 0
                              ) {
                                drilldownHandler("vehicle_most_incident", 6);
                              }
                            }}
                            mt={2}
                          >
                            <h2 className="titletop">
                              Vehicles with the Most Incidents
                            </h2>
                            <BarChart
                              labels={vehicleWithMostIncident.labels}
                              datasets={vehicleWithMostIncident.datasets}
                              options={options}
                              height="auto"
                            />
                          </Grid>
                          <Grid
                            md={12}
                            className="totalhour commonBoxShodow"
                            gap={3}
                            item
                            mt={3}
                            spacing={3}
                          >
                            <Grid md={8} xs={12}>
                              <FormControl fullWidth>
                                <FormLabels
                                  label={"Total Hours Worked"}
                                  isRequired={false}
                                />
                                <InputField
                                  fullWidth={true}
                                  disabled={false}
                                  type="text"
                                  placeholder="Please enter here..."
                                  onChange={onchangeHandleTrir}
                                  value={trir}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item textAlign="center" mt={3}>
                              <Buttons // TODO
                                disabled={false}
                                varientContained={true}
                                label={"Calculate"}
                                onClick={calculateTrir}
                              />
                            </Grid>
                            <div className="trir">
                              <p>
                                TRIR = (Number of recordable incidents) X
                                200,000 / (Total number of hours worked)
                              </p>{" "}
                            </div>
                            {trirResultFinal && (
                              <div className="trir h1">
                                <h1>TRIR = {trirResultFinal}</h1>
                              </div>
                            )}
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Grid container>
                        {" "}
                        <Grid md={12} mt={2}>
                          <div
                            className="inc_opencase cursor"
                            onClick={() => drilldownOpenCases("totalcases", 3)}
                          >
                            <TopSection1
                              title={"Oldest Open Cases"}
                              column={CORRENT_OPEN_CASE}
                              rows={currentOpenCasesList}
                              topsection={"OPENCASE"}
                            />
                          </div>
                        </Grid>
                        <Grid md={12} mt={2}>
                          <div
                            className="inc_mostinc cursor"
                            onClick={() => {
                              if (userWithIncidentListData?.length > 0) {
                                drilldownHandler("user_most_incident", 5);
                              }
                            }}
                          >
                            <TopSection1
                              title={"Users with Most Incidents"}
                              column={USER_WITH_MOST_INCIDENT}
                              rows={userWithIncidentListData}
                              topsection={"USER_WITH_MOST_INC"}
                            />
                          </div>{" "}
                        </Grid>
                        <Grid md={12} mt={2}>
                          <div
                            className="inc_actitem cursor"
                            onClick={() => {
                              if (userWithActionListData?.length > 0) {
                                drilldownHandler("user_most_action", 7);
                              }
                            }}
                          >
                            <TopSection1
                              title={"Users with Most Action Items"}
                              column={USER_WITH_MOST_ACTION}
                              rows={userWithActionListData}
                              topsection={"USER_WITH_MOSTACTION"}
                            />
                          </div>
                        </Grid>
                        {/* <Grid item md={12} xs={12} mt={2}>
                          <div
                            className="commonBoxShodow cursor"
                            onClick={() => drilldownOpenCases("totalcases", 2)}
                          >
                            <div className="angtimetoclose">
                              <div className="text">
                                Average Time to close cases
                              </div>
                              {averageTimeToClose ? (
                                <div className="avgtime">
                                  <p>
                                    {averageTimeToClose.toFixed(2)}
                                    days
                                  </p>
                                </div>
                              ) : (
                                <div className="avgtime">
                                  <p>
                                    &nbsp; &nbsp;
                                    {0}
                                    &nbsp;days
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </div>
            )}
          </>
        ) : incidentType === 2 ? (
          <>
            <div id="first-page">
              <Grid container>
                <Grid item md={12}>
                  <h1>Overview</h1>
                </Grid>
                {incidentType && timePeriod && (
                  <Grid item md={12} xs={12}>
                    <Button
                      onClick={() =>
                        downloadFullPDF("pdf-content", "incident.pdf", [
                          "first-page",
                          "second-page",
                        ])
                      }
                      variant="contained"
                      startIcon={<DownloadIcon className="downloadIcon" />}
                      className="downloadpdf sw marginRemove float_right"
                    >
                      Download as PDF
                    </Button>
                  </Grid>
                )}

                <Grid container mt={0} spacing={2}>
                  <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                      <FormLabels label="Incident Type" isRequired={true} />
                      <SelectMenu
                        listData={associatedIncidentType1}
                        value={incidentType || ""}
                        onchangehandler={(e) => handleIncidentTypeChange(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {incidentType && (
                  <>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={false} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={divisions}
                            value={divisionId}
                            onchangehandler={(e) => handleDivisionChange(e)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels
                            label="Establishment"
                            isRequired={false}
                          />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={locationOfRecordData ?? []}
                            value={locationOfRecord ? locationOfRecord : null}
                            onchangehandler={(e) =>
                              handleLocationOfRecordChange(e)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      {incidentType && (
                        <>
                          <Grid md={4} xs={12} item>
                            <FormControl fullWidth>
                              <FormLabels
                                label="Time Period"
                                isRequired={true}
                              />
                              <SelectMenu
                                listData={TIMEPERIOD}
                                value={timePeriod}
                                onchangehandler={(e) =>
                                  handleTimePeriodChange(e)
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            {incidentType && timePeriod === 5 ? (
                              <>
                                <Grid item container spacing={2} mb={2} mt={0}>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"From"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      value={startDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeStartdatePicker(e)
                                      }
                                    />
                                  </Grid>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"To"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      minDate={startDate}
                                      value={endDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeEnddatePicker(e)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              {incidentType && (
                <Grid container spacing={2} mt={2}>
                  <Grid container md={12}>
                    <Grid md={9}>
                      <LineChart
                        labels={
                          generateLineChartData(processedData || []).labels
                        }
                        datasets={
                          generateLineChartData(processedData || []).datasets
                        }
                        options={linechartoptions}
                        height="auto"
                      />
                    </Grid>
                    <Grid md={3} xs={12} className="totalincbox">
                      <Grid
                        container
                        className="incidentreportboxs cursor"
                        onClick={
                          incidentType === 2
                            ? () =>
                                drilldownLineChart("report_VehicleAccident", 2)
                            : ""
                        }
                      >
                        <Grid item md={12} className="commonBoxShodow">
                          <div className={"lessoreportCardBody"}>
                            <div className="text">
                              {incidentType === 2 ? "Vehicle Accident" : ""}
                            </div>
                            <div
                              className={`pernumber ${
                                incidentType === 2 ? "veh" : ""
                              }`}
                            >
                              {incidentType === 2
                                ? VehicleAccidentdata?.length
                                : ""}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* {incidentType === -1 ? (
                      <>
                        <Grid container className="boxes" mt={2}>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            className="incidentreportboxs cursor"
                            onClick={() =>
                              drilldownLineChart("report_personaInjury", 1)
                            }
                          >
                            <Grid className="commonBoxShodow box">
                              <div className={"lessoreportCardBody"}>
                                <div className="text">Personal Injury</div>
                                <div className="number">
                                  {personalenjurtdata?.length}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className="incidentreportboxs cursor"
                            xs={12}
                            onClick={() =>
                              drilldownLineChart("report_VehicleAccident", 2)
                            }
                          >
                            <Grid className="commonBoxShodow box">
                              <div className={"lessoreportCardBody"}>
                                <div className="text">Vehicle Accident</div>
                                <div className="number">
                                  {VehicleAccidentdata?.length}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            className="incidentreportboxs cursor"
                            onClick={() =>
                              drilldownLineChart("report_StolenProperty", 3)
                            }
                          >
                            <Grid className="commonBoxShodow box">
                              <div className={"lessoreportCardBody"}>
                                <div className="text">Stolen Property</div>
                                <div className="number">
                                  {StolenPropertydata?.length}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            className="incidentreportboxs cursor"
                            onClick={() =>
                              drilldownLineChart("report_UtilityDamage", 4)
                            }
                          >
                            <Grid className="commonBoxShodow box">
                              <div className={"lessoreportCardBody"}>
                                <div className="text">Utility Damage</div>
                                <div className="number">
                                  {UtilityDamagedata?.length}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            className="incidentreportboxs cursor"
                            onClick={() =>
                              drilldownLineChart("report_NearMiss", 5)
                            }
                          >
                            <Grid className="commonBoxShodow box">
                              <div className={"lessoreportCardBody"}>
                                <div className="text">Near Miss</div>
                                <div className="number">
                                  {NearMissdata?.length}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                </Grid>
              )}
            </div>
            <div id="second-page">
              <>
                {" "}
                {incidentType && (
                  <>
                    <Grid container spacing={2} mt={2}>
                      <>
                        <Grid item md={8} xs={12}>
                          {incidentType === 2 ? (
                            <>
                              <Grid
                                className="commonBoxShodow cursor"
                                onClick={() => {
                                  if (
                                    vehicleWithMostIncident.datasets?.length > 0
                                  ) {
                                    drilldownHandler(
                                      "vehicle_most_incident",
                                      6
                                    );
                                  }
                                }}
                              >
                                <h2 className="titletop">
                                  Vehicles with the Most Incidents
                                </h2>
                                <BarChart
                                  labels={vehicleWithMostIncident.labels}
                                  datasets={vehicleWithMostIncident.datasets}
                                  options={options}
                                  height="auto"
                                />
                              </Grid>{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>

                      <Grid item md={4} xs={12}>
                        <div
                          className="inc_mostinc cursor"
                          onClick={() => {
                            if (userWithIncidentListData?.length > 0) {
                              drilldownHandler("user_most_incident", 5);
                            }
                          }}
                        >
                          <TopSection1
                            title={"Users with Most Incidents"}
                            column={USER_WITH_MOST_INCIDENT}
                            rows={userWithIncidentListData}
                            topsection={"USER_WITH_MOST_INC"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                      {/* <Grid item md={4} xs={12}>
                        <div
                          className="commonBoxShodow cursor"
                          onClick={() => drilldownOpenCases("totalcases", 2)}
                        >
                          <div className="angtimetoclose">
                            <div className="text">
                              Average Time to close cases
                            </div>
                            {averageTimeToClose ? (
                              <div className="avgtime">
                                <p>
                                  {averageTimeToClose.toFixed(2)}
                                  days
                                </p>
                              </div>
                            ) : (
                              <div className="avgtime">
                                <p>
                                  &nbsp; &nbsp;
                                  {0}
                                  &nbsp;days
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid> */}
                      <Grid item md={4} xs={12}>
                        <div
                          className="inc_opencase cursor"
                          onClick={() => drilldownOpenCases("totalcases", 3)}
                        >
                          <TopSection1
                            title={"Oldest Open Cases"}
                            column={CORRENT_OPEN_CASE}
                            rows={currentOpenCasesList}
                            topsection={"OPENCASE"}
                          />
                        </div>
                      </Grid>

                      <Grid item md={4} xs={12}>
                        {" "}
                        <div
                          className="inc_actitem cursor"
                          onClick={() => {
                            if (userWithActionListData?.length > 0) {
                              drilldownHandler("user_most_action", 7);
                            }
                          }}
                        >
                          <TopSection1
                            title={"Users with Most Action Items"}
                            column={USER_WITH_MOST_ACTION}
                            rows={userWithActionListData}
                            topsection={"USER_WITH_MOSTACTION"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            </div>
          </>
        ) : incidentType === 3 || incidentType === 4 || incidentType === 5 ? (
          <>
            <div id="first-page">
              <Grid container>
                <Grid item md={12}>
                  <h1>Overview</h1>
                </Grid>
                {incidentType && timePeriod && (
                  <Grid item md={12} xs={12}>
                    <Button
                      onClick={() =>
                        downloadFullPDF("pdf-content", "incident.pdf", [
                          "first-page",
                          "second-page",
                        ])
                      }
                      variant="contained"
                      startIcon={<DownloadIcon className="downloadIcon" />}
                      className="downloadpdf sw marginRemove float_right"
                    >
                      Download as PDF
                    </Button>
                  </Grid>
                )}

                <Grid container mt={0} spacing={2}>
                  <Grid md={4} xs={12} item>
                    <FormControl fullWidth>
                      <FormLabels label="Incident Type" isRequired={true} />
                      <SelectMenu
                        listData={associatedIncidentType1}
                        value={incidentType || ""}
                        onchangehandler={(e) => handleIncidentTypeChange(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {incidentType && (
                  <>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels label="Division" isRequired={false} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={divisions}
                            value={divisionId}
                            onchangehandler={(e) => handleDivisionChange(e)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      <Grid md={4} xs={12} item>
                        <FormControl fullWidth>
                          <FormLabels
                            label="Establishment"
                            isRequired={false}
                          />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={locationOfRecordData ?? []}
                            value={locationOfRecord ? locationOfRecord : null}
                            onchangehandler={(e) =>
                              handleLocationOfRecordChange(e)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container mt={1} spacing={2}>
                      {incidentType && (
                        <>
                          <Grid md={4} xs={12} item>
                            <FormControl fullWidth>
                              <FormLabels
                                label="Time Period"
                                isRequired={true}
                              />
                              <SelectMenu
                                listData={TIMEPERIOD}
                                value={timePeriod}
                                onchangehandler={(e) =>
                                  handleTimePeriodChange(e)
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            {incidentType && timePeriod === 5 ? (
                              <>
                                <Grid item container spacing={2} mb={2} mt={0}>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"From"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      value={startDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeStartdatePicker(e)
                                      }
                                    />
                                  </Grid>
                                  <Grid item lg={4} xs={12} sm={12} md={4}>
                                    <FormLabels
                                      label={"To"}
                                      isRequired={false}
                                    />
                                    <DatePicker
                                      maxDateEnable={false}
                                      minDate={startDate}
                                      value={endDate}
                                      disabled={false}
                                      onChangeHandler={(e) =>
                                        onchangeEnddatePicker(e)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              {incidentType && (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid container md={12}>
                      <Grid md={9}>
                        <LineChart
                          labels={
                            generateLineChartData(processedData || []).labels
                          }
                          datasets={
                            generateLineChartData(processedData || []).datasets
                          }
                          options={linechartoptions}
                          height="auto"
                        />
                      </Grid>
                      <Grid md={3} xs={12} className="totalincbox">
                        <Grid
                          container
                          className="incidentreportboxs cursor"
                          onClick={
                            incidentType === -1
                              ? () =>
                                  drilldownLineChart("report_totalIncidents", 0)
                              : incidentType === 1
                              ? () =>
                                  drilldownLineChart("report_personaInjury", 1)
                              : incidentType === 2
                              ? () =>
                                  drilldownLineChart(
                                    "report_VehicleAccident",
                                    2
                                  )
                              : incidentType === 3
                              ? () =>
                                  drilldownLineChart("report_StolenProperty", 3)
                              : incidentType === 4
                              ? () =>
                                  drilldownLineChart("report_UtilityDamage", 4)
                              : incidentType === 5
                              ? () => drilldownLineChart("report_NearMiss", 5)
                              : ""
                          }
                        >
                          <Grid item md={12} className="commonBoxShodow">
                            <div className={"lessoreportCardBody"}>
                              <div className="text">
                                {incidentType === -1
                                  ? "Total Incidents"
                                  : incidentType === 1
                                  ? "Personal Injury"
                                  : incidentType === 2
                                  ? "Vehicle Accident"
                                  : incidentType === 3
                                  ? "Stolen Property"
                                  : incidentType === 4
                                  ? "Utility Damage"
                                  : incidentType === 5
                                  ? "Near Miss"
                                  : ""}
                              </div>
                              <div
                                className={`pernumber ${
                                  incidentType === -1
                                    ? "total"
                                    : incidentType === 1
                                    ? "per"
                                    : incidentType === 2
                                    ? "veh"
                                    : incidentType === 3
                                    ? "stolen"
                                    : incidentType === 4
                                    ? "utility"
                                    : incidentType === 5
                                    ? "nearmiss"
                                    : ""
                                }`}
                              >
                                {incidentType === -1
                                  ? fulldata?.length
                                  : incidentType === 1
                                  ? personalenjurtdata?.length
                                  : incidentType === 2
                                  ? VehicleAccidentdata?.length
                                  : incidentType === 3
                                  ? StolenPropertydata?.length
                                  : incidentType === 4
                                  ? UtilityDamagedata?.length
                                  : incidentType === 5
                                  ? NearMissdata?.length
                                  : ""}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
            {incidentType && (
              <div id="second-page">
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid md={8} xs={12} mt={2}>
                      <Grid
                        className="commonBoxShodow cursor"
                        onClick={() => {
                          if (projectWithMostIncident.datasets?.length > 0) {
                            drilldownHandler("project_most_incident", 4);
                          }
                        }}
                      >
                        <h2 className="titletop">
                          Projects with the Most Incidents
                        </h2>
                        <BarChart
                          labels={projectWithMostIncident.labels}
                          datasets={projectWithMostIncident.datasets}
                          options={options}
                          height="auto"
                        />
                      </Grid>
                      <Grid container mt={2} spacing={2}>
                        {" "}
                        {/* <Grid item md={6} xs={12}>
                          <div
                            className="commonBoxShodow cursor"
                            onClick={() => drilldownOpenCases("totalcases", 2)}
                          >
                            <div className="angtimetoclose">
                              <div className="text">
                                Average Time to close cases
                              </div>
                              {averageTimeToClose ? (
                                <div className="avgtime">
                                  <p>
                                    {averageTimeToClose.toFixed(2)}
                                    days
                                  </p>
                                </div>
                              ) : (
                                <div className="avgtime">
                                  <p>
                                    &nbsp; &nbsp;
                                    {0}
                                    &nbsp;days
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid> */}
                        <Grid item md={6} xs={12}>
                          <div
                            className="inc_opencase cursor"
                            onClick={() => drilldownOpenCases("totalcases", 3)}
                          >
                            <TopSection1
                              title={"Oldest Open Cases"}
                              column={CORRENT_OPEN_CASE}
                              rows={currentOpenCasesList}
                              topsection={"OPENCASE"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <div
                            className="inc_mostinc cursor"
                            onClick={() => {
                              if (userWithIncidentListData?.length > 0) {
                                drilldownHandler("user_most_incident", 5);
                              }
                            }}
                          >
                            <TopSection1
                              title={"Users with Most Incidents"}
                              column={USER_WITH_MOST_INCIDENT}
                              rows={userWithIncidentListData}
                              topsection={"USER_WITH_MOST_INC"}
                            />
                          </div>
                        </Grid>
                        <Grid item md={12} xs={12} mt={2}>
                          <div
                            className="inc_actitem cursor"
                            onClick={() => {
                              if (userWithIncidentListData?.length > 0) {
                                drilldownHandler("user_most_action", 7);
                              }
                            }}
                          >
                            <TopSection1
                              title={"Users with Most Action Items"}
                              column={USER_WITH_MOST_ACTION}
                              rows={userWithActionListData}
                              topsection={"USER_WITH_MOSTACTION"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Incidents;
