import { Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import Buttons from "../../../../library/custom/button/Button";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import {
  ISwitch,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import "./configureInspections.scss";
import { STATUS } from "../../../../constant/storm-water/constant";
import { useNavigate } from "react-router-dom";
import { SW_INSPECTION_CONFIGURATION } from "../../../../constant/routeContant";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const SetupInspectionList = ({
  setupInspection,
  swQuestionSetProjectMapList,
  isLoading,
  count,
  getSwQuestionSetProjectMapListData,
  handleEditOnClick,
  updateSwQuestionSetProjectMapData,
  allProjects,
  handleAllProjectSearch,
  isProjectLoading,
  searchClient,
  isSearchClientLoading,
  allClients,
  handleManageOnClick,
  updateStatusQuestionSetProjectMap,
  projectData,
}) => {
  useEffect(() => {
    if (projectData?.customerId) {
      setClients({
        key: projectData?.customerId,
        value: projectData?.customerName,
      });
      setCustomerId(projectData?.customerId);
      setProjects("");
      setProjectId(projectData?.projectId ?? 0);
      setshwoProjectName(true);
      setOrgId(
        projectData?.customerId ? projectData?.customerId : parseInt(ORG_ID())
      );
      const data = {
        page: page,
        search: search ? search : "",
        customerId: projectData?.customerId,
        projectId: projectData?.projectId ?? 0,
        userId: 0,
        status: statusIds === 1 ? true : statusIds === 2 ? false : "",
        pageSize: pageSize,
        orgId: projectData?.customerId
          ? projectData?.customerId
          : parseInt(ORG_ID()),
      };
      getSwQuestionSetProjectMapListData(data);
    }
    if (projectData?.projectId) {
      setProjects({
        key: projectData?.projectId,
        value: projectData?.projectName,
      });
      setProjectId(projectData?.projectId);
      setshwoProjectName(true);
      setOrgId(
        projectData?.customerId ? projectData?.customerId : parseInt(ORG_ID())
      );
      setClients({
        key: projectData?.customerId,
        value: projectData?.customerName,
      });
      setCustomerId(projectData?.customerId);
      setshwoCustomerName(true);
      const data = {
        page: page,
        search: search ? search : "",
        customerId: projectData?.customerId,
        projectId: projectData?.projectId ?? 0,
        userId: 0,
        status: statusIds === 1 ? true : statusIds === 2 ? false : "",
        pageSize: pageSize,
        orgId: projectData?.customerId
          ? projectData?.customerId
          : parseInt(ORG_ID()),
      };
      getSwQuestionSetProjectMapListData(data);
    }
    // getProjectContactsListAction(data);
  }, [projectData]);

  const navigate = useNavigate();
  const [shwoCustomerName, setshwoCustomerName] = useState(false);
  const [shwoProjectName, setshwoProjectName] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [statusIds, setStatusIds] = useState();
  const [customerId, setCustomerId] = useState();
  const [projectId, setProjectId] = useState();
  const [filter, setFilter] = useState({
    searchType: [],
  });
  const [orgId, setOrgId] = useState("");
  const [projects, setProjects] = useState("");
  const [clients, setClients] = useState();
  const autoComp = useRef(null);
  const onChangeProjects = (value) => {
    setshwoProjectName(false);
    setProjects(value);
    setProjectId(value?.key);
    const data = {
      page: page,
      search: search ? search : "",
      customerId: customerId ? customerId : 0,
      projectId: value?.key ? value?.key : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: pageSize,
      orgId: orgId ? orgId : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
  };

  const onChangeClients = (value) => {
    setshwoCustomerName(false);
    setClients(value);
    setCustomerId(value?.key);
    setOrgId(value?.key);
    const data = {
      page: page,
      search: search ? search : "",
      customerId: value?.key ? value?.key : 0,
      projectId: projectId ? projectId : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: pageSize,
      orgId: value?.key ? value?.key : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      search: search ? search : "",
      customerId: customerId ? customerId : 0,
      projectId: projectId ? projectId : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: pageSize,
      orgId: orgId ? orgId : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: page,
      search: search ? search : "",
      customerId: customerId ? customerId : 0,
      projectId: projectId ? projectId : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      pageSize: newPazeSize,
      orgId: orgId ? orgId : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: event.target.value,
      customerId: customerId ? customerId : 0,
      projectId: projectId ? projectId : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: pageSize,
      orgId: orgId ? orgId : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
  };

  const handleChange = (item) => {
    const newTypeList = [...swQuestionSetProjectMapList];
    newTypeList.forEach((lists) => {
      if (lists.mapId == item.mapId) {
        lists.status = !lists.status;
        lists.checked = !lists.checked;
        updateSwQuestionSetProjectMapData({
          mapId: lists?.mapId,
          status: lists?.status,
          userId: parseInt(USER_ID()),
          orgId: orgId ? parseInt(orgId) : parseInt(ORG_ID()),
        });
      }
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dataBack = {
    title: "Back to Inspection Configuration",
    route: SW_INSPECTION_CONFIGURATION,
  };
  const handleApply = () => {
    const data = {
      search: search ? search : "",
      customerId: customerId ? customerId : 0,
      projectId: projectId ? projectId : 0,
      userId: 0,
      status: statusIds === 1 ? true : statusIds === 2 ? false : "",
      page: page,
      pageSize: pageSize,
      orgId: orgId ? orgId : parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(data);
    handleClose();
  };

  const handleClear = () => {
    setSearch("");
    setStatusIds("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    const dataClear = {
      search: "",
      userId: 0,
      customerId: shwoProjectName ? customerId : 0,
      projectId: shwoCustomerName ? projectId : 0,
      page: 1,
      pageSize: pageSize,
      status: "",
      orgId: parseInt(ORG_ID()),
    };
    getSwQuestionSetProjectMapListData(dataClear);
    handleClose();
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const columns = [
    {
      field: "projectNo",
      headerName: "Project No",
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Client",
      flex: 1,
    },

    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "inspectionTypeName",
      headerName: "Inspection Type",
      flex: 1,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1.3,
      renderCell: (rows) => {
        return (
          <>
            <div>
              <>
                {rows?.row?.frequency}{" "}
                {rows?.row?.frequencyId === 7 ? (
                  <span
                    className="frequencyInDays"
                    title={rows?.row?.frequencyInDays}
                  >
                    {"- " + rows?.row?.frequencyInDays}
                  </span>
                ) : (
                  ""
                )}
              </>
            </div>
          </>
        );
      },
    },
    {
      field: "questionSetName",
      headerName: "Form",
      flex: 1,
    },
    {
      field: "primaryInspector",
      headerName: "Primary Inspector",
      flex: 1,
    },
    {
      flex: 1.3,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 99]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={rows?.row.status ? true : false}
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}

                <div title={rows?.row?.status ? "Active" : "Inactive"}>
                  {rows?.row?.status ? "Active" : "Inactive"}
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={count ?? 0}
          numberOfItemsPerPage={10}
          incidentLists={swQuestionSetProjectMapList ?? []}
          fileNameXL={"inspections Type"}
        />
      ),
      flex: 0.7,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            edit={checkPrivileges([12, 99, 100])}
            editOnClick={(row) => handleEditOnClick(row, customerId)}
            editLabel="Edit Project Map"
            manageOnClick={(row) => handleManageOnClick(row)}
            manageLabel="Manage Insp. Authority"
            manage={false}
          />
        );
      },
    },
  ];
  useEffect(() => {
    if (
      updateStatusQuestionSetProjectMap?.status === "SUCCESS" &&
      updateStatusQuestionSetProjectMap?.isLoading === false
    ) {
      const data = {
        page: page,
        search: search ? search : "",
        customerId: customerId ? customerId : "",
        projectId: projectId ? projectId : 0,
        userId: 0,
        status: statusIds === 1 ? true : statusIds === 2 ? false : "",
        pageSize: pageSize,
        orgId: orgId ? orgId : parseInt(ORG_ID()),
      };
      getSwQuestionSetProjectMapListData(data);
    }
  }, [updateStatusQuestionSetProjectMap]);

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <Grid container item md={12} xs={12} className="">
            <Grid container className="confBox">
              <Grid item md={8} xs={12}>
                <div className="confTitile">Inspection Service List</div>
              </Grid>
              <Grid item md={4} xs={12}>
                {checkPrivileges([12, 99, 100]) && (
                  <Buttons
                    id="btnAddCategory"
                    label={"Setup Inspection"}
                    varientAddIconBlue={true}
                    onClick={() => setupInspection()}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Grid container mb={2}>
          <Grid lg={8} md={12} xs={12} pr={2} item>
            <Grid container spacing={2}>
              {ORG_ID() == 1 && (
                <Grid lg={6} xs={12} sm={12} md={6} item>
                  <FormControl fullWidth>
                    <FormLabels label="Client" isRequired={false} />
                    <SearchAutoComplete
                      name="Add Individual Users"
                      optionsData={allClients ?? []}
                      typeOnchange={(event, value) =>
                        searchClient(event, value)
                      }
                      isKeyValuePair={true}
                      value={clients}
                      onChange={(e, value) => onChangeClients(value)}
                      isLoading={isSearchClientLoading}
                      autoCompClear={autoComp}
                      noOptionsTextMsg={"No Client"}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid lg={6} xs={12} sm={12} md={6} item>
                <FormControl fullWidth>
                  <FormLabels label="Project" isRequired={false} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    optionsData={allProjects ?? []}
                    typeOnchange={(event, value) =>
                      handleAllProjectSearch(event, value, customerId)
                    }
                    isKeyValuePair={true}
                    value={projects}
                    onChange={(e, value) => onChangeProjects(value)}
                    isLoading={isProjectLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg={"No Project"}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>

              <FilterCommonStatusSelect
                // id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                statusType={STATUS}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                onChangeType={onChangeType}
                statusIds={statusIds}
                filterSelect={false}
                filterStatus={true}
                resetBtn={true}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.mapId}
                rows={swQuestionSetProjectMapList ?? []}
                rowCount={count ?? 0}
                columns={columns}
                checkboxSelection={false}
                loading={isLoading}
                page={1}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
            <div className="insBackBtn">
              <BackBtn dataBack={dataBack} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SetupInspectionList;
