import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import "./addClient.scss";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import DialogBox from "../../../../library/common/ConfirmBox";
import SettingTable from "../../../incidents/incidentList/SettingTable";

export default function ViewClient({
  getClientDetsilsData,
  dataBack,
  Organisationid,
  getSynchWithProcore,
  isProcoreConnected,
  setIsSuccess,
  getProcoreCompanyData,
  isLoading,
  getProcoreCompanyLists,
  procoreCompanyId,
}) {
  const data = getClientDetsilsData?.result;
  const imageSize = {
    size: "small",
  };

  const [image, setImage] = useState([]);
  const [platformTypes, setPlatformTypes] = useState({
    internal: false,
    external: false,
  });
  useEffect(() => {
    setImage(data?.logoUrl);
    if (data?.platform?.includes(1)) {
      setPlatformTypes((prevState) => ({ ...prevState, internal: true }));
    }
    if (data?.platform?.includes(2)) {
      setPlatformTypes((prevState) => ({ ...prevState, external: true }));
    }
  }, [data]);
  useEffect(() => {
    console.log(image);
  }, [image]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #000",
    p: 1,
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const onClickSynchandler = () => {
    const defaultdata = {
      page: 1,
      pageSize: 10000,
    };
    getProcoreCompanyLists(defaultdata);
    setOpen(true);
  };

  const disconnectClienthandle = () => {
    setOpenModal(false);
    const defaultData = {
      disconnectClient: isProcoreConnected ? true : false,
      orgId: Organisationid,
      procoreCompanyId: procoreCompanyId,
      procoreCompanyName: data?.procoreCompanyName,
    };
    getSynchWithProcore(defaultData);
    setIsSuccess(true);
  };

  const onClickDisconnectProcore = () => {
    setOpenModal(true);
  };

  const onClickClientSynchandler = () => {
    setOpen(false);
    let array = selectionModel;
    let integerValue = array[0];
    let procoreComName = selectedRows?.map((item) => item.name);
    let integerNameValue = procoreComName[0];
    const defaultData = {
      disconnectClient: isProcoreConnected ? true : false,
      orgId: Organisationid,
      procoreCompanyId: integerValue,
      procoreCompanyName: integerNameValue,
    };
    getSynchWithProcore(defaultData);
    setIsSuccess(true);
  };

  const credcloumns = [
    { field: "id", headerName: "ID", flex: 2 },
    { field: "name", headerName: "Client Name", flex: 2.8 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={getProcoreCompanyData?.listCompany?.length ?? 0}
          numberOfItemsPerPage={10000}
          incidentLists={getProcoreCompanyData?.listCompany ?? []}
          fileNameXL={"ProcorCompantList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          header={"Confirm Disconnect"}
          deletehandle={disconnectClienthandle}
          handleCloseModal={() => setOpenModal(false)}
          content={
            "Are you sure you want to disconnect this client with Procore ?"
          }
          view
        />
      )}
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="viewOverideContact">
        <div className="commonOveride">
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid mt={1} mb={1}>
                  <div
                    style={{
                      fontFamily: "lato",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                    className="ProcoreCompanyListHeading"
                  >
                    Procore Company List
                  </div>
                </Grid>
                <Grid item container lg={12}>
                  <Grid lg={12}>
                    <div className="desc_box row_uniq">
                      <div className="table_style_ui">
                        <NormalGrid
                          loading={isLoading}
                          getRowId={(r) => r.id}
                          rowss={getProcoreCompanyData?.listCompany ?? []}
                          columnss={credcloumns}
                          rowCount={
                            getProcoreCompanyData?.listCompany?.length ?? []
                          }
                          selectionModel={selectionModel}
                          onSelectionModelChange={(e) => {
                            setSelectionModel(e);
                            const selectedIDs = new Set(e);
                            const selectedRows =
                              getProcoreCompanyData?.listCompany.filter((r) =>
                                selectedIDs.has(r.id)
                              );
                            setSelectedRows(selectedRows);
                          }}
                          disableMultipleSelection={true}
                        ></NormalGrid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid mt={2} item container lg={12}>
                  <Grid lg={7}></Grid>
                  <Grid lg={5}>
                    <Button
                      variant="contained"
                      style={{
                        height: "44px",
                        backgroundColor: "#34A853",
                        color: "white",
                        borderRadius: "100px",
                        textTransform: "none",
                        boxShadow: "none",
                        transition: "none",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "0px 38px",
                      }}
                      onClick={onClickClientSynchandler}
                    >
                      Connect with Procore
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
          <Typography className="titleDetail">{data?.fullName}</Typography>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Typography className="title">Details:</Typography>
                <Box className="columns">
                  <Typography className="listView">
                    Client ID:
                    <span className="font-w400">{Organisationid}</span>
                  </Typography>
                  <Typography className="listView">
                    Industry Type:
                    <span className="font-w400">{data?.industryType}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Point Of Contact Name:
                    <span className="font-w400">{data?.pointOfContact}</span>
                  </Typography>
                  <Typography className="listView">
                    Client Short Name:
                    <span className="font-w400">{data?.shortName}</span>
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    No. of Employees:
                    <span className="font-w400">{data?.noOfEmployees}</span>
                  </Typography>
                  <Typography className="listView">
                    Preferred Language:
                    <span className="font-w400">
                      {" "}
                      {data?.preferredLanguageId === 1 ? "English" : "Spanish"}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Address1:
                    <span className="font-w400">{data?.address1}</span>
                  </Typography>
                  <Typography className="listView">
                    Address2:
                    <span className="font-w400">{data?.address2}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    State:
                    <span className="font-w400">{data?.stateName}</span>
                  </Typography>
                  <Typography className="listView">
                    City:
                    <span className="font-w400">{data?.cityName}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Zip Code:
                    <span className="font-w400">{data?.zipCode}</span>
                  </Typography>
                  <Typography className="listView">
                    Phone Number:
                    <span className="font-w400">{data?.phoneNumber}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Email Id:
                    <span className="font-w400">{data?.email}</span>
                  </Typography>
                  <Typography className="listView">
                    Website:
                    <span className="font-w400">{data?.webSite}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView ">
                    Product:
                    <span className="font-w400">
                      {data?.product?.map((val, index) => {
                        return (
                          <spna key={index}>
                            {(index ? ", " : " ") + val?.value}
                          </spna>
                        );
                      })}
                    </span>
                  </Typography>
                  {data?.product?.find((x) => x?.key === 2) && (
                    <Typography className="listView">
                      Media Allowed For Stormwater Inspections :
                      <span className="font-w400">
                        {data?.isMediaAllowed == true ? "Yes" : "No"}
                      </span>
                    </Typography>
                  )}
                </Box>
                {data?.product?.find((x) => x?.key === 2) && (
                  <>
                    <Box>
                      <Typography className="listView inspectionPlatform">
                        <span className="blockView inspectionPlatformTypeTitle">
                          Stormwater Inspection Platform:
                        </span>
                        <div className="inspectionPlatformType">
                          <span className="blockView">
                            <strong>Internal</strong>:{" "}
                            {platformTypes?.internal ? "Yes" : "No"}
                          </span>
                          <span className="blockView">
                            <strong>External</strong>:{" "}
                            {platformTypes?.external ? "Yes" : "No"}
                          </span>
                        </div>
                      </Typography>
                    </Box>
                    <Box className="columns">
                      <Typography className="listView">
                        Stormwater PDF Email Notification:
                        <span className="font-w400">
                          {data?.isInsCombinedPdf === true
                            ? "1 Report"
                            : "3 Reports"}
                        </span>
                      </Typography>
                    </Box>
                  </>
                )}
                <Box>
                  <Typography className="listView ">
                    Client Logo:
                    <span className="blockView">
                      <ViewImages
                        image={image?.length > 0 ? [image] : []}
                        imageSize={imageSize}
                      />
                    </span>
                    {/* )} */}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <Grid
        mt={4}
        style={{ display: "flex", alignItems: "center", gap: "50px" }}
      >
        <Button
          variant="contained"
          style={{
            height: "44px",
            backgroundColor: isProcoreConnected ? "#D30D25" : "#34A853",
            color: "white",
            borderRadius: "100px",
            textTransform: "none",
            boxShadow: "none",
            transition: "none",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0px 38px",
          }}
          onClick={() => {
            if (isProcoreConnected) {
              onClickDisconnectProcore();
            } else {
              onClickSynchandler();
            }
          }}
        >
          {isProcoreConnected ? "Disconnect with Procore" : "Map with Procore"}
        </Button>
        {isProcoreConnected && (
          <Grid
            style={{ fontFamily: "lato", fontSize: "16px", color: "#D30D25" }}
          >
            Connected to :{" "}
            {data?.procoreCompanyName === null ? "" : data?.procoreCompanyName}
          </Grid>
        )}
      </Grid>
    </>
  );
}
