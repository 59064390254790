import React, { useEffect, useState } from "react";
import CaseList from "../components/caseList";
import {
  ACCESS_DENIED,
  ADDCASE,
  CASEINCIDENTS,
  EDITCASE,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { API_STATUS } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";

const CasesContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createCase = () => {
    navigate(ADDCASE);
  };

  const deleteCaseData = useSelector((state) => state.deleteCase);
  const cases = useSelector((state) => state.casesList);
  const isLoading = useSelector(
    (state) => state.casesList?.isLoading || state.deleteCase?.isLoading
  );
  const [incedentPending, setIncedentPending] = useState(false);
  const [clearMessage, setClearMessage] = useState(false);

  const defaultData = {
    page: 1,
    search: "",
    date_from: "",
    date_to: "",
    statusIds: "",
  };

  useEffect(() => {
    getCasesListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      deleteCaseData?.status === API_STATUS?.SUCCESS &&
      deleteCaseData?.data?.responseMessages?.responseMessage?.toLowerCase() ===
        "unable to delete the case until all incidents have been deleted" &&
      clearMessage
    ) {
      setIncedentPending(true);
    }
    if (
      deleteCaseData?.status === API_STATUS?.SUCCESS &&
      deleteCaseData?.data?.responseMessages?.responseMessage?.toLowerCase() ===
        "request was successful" &&
      clearMessage
    ) {
      toast("Case Deleted successfully");
      setIncedentPending(false);
      getCasesListData(defaultData);
    }
  }, [deleteCaseData]);

  const getCasesListData = (data) => {
    dispatch(incidentAction.getCasesList(data));
  };

  const handleViewOnClick = (caseId) => {
    navigate(CASEINCIDENTS, {
      state: { caseId: caseId },
    });
  };

  const handleEditOnClick = (caseId) => {
    navigate(EDITCASE, {
      state: {
        caseId: caseId,
        editMode: true,
        isEditModeThroughViewCase: false,
      },
    });
  };

  const deleteCase = (deleteCase) => {
    setClearMessage(true);
    dispatch(incidentAction.deleteCaseList(deleteCase.caseId));
  };

  return (
    <>
      {checkPrivileges([12, 149, 150, 151]) ? (
        <CaseList
          createCase={createCase}
          isFullAccess={checkPrivileges([151, 12])}
          isWriteAccess={checkPrivileges([150, 12])}
          isReadAccess={checkPrivileges([149, 12])}
          casesData={isLoading ? [] : cases?.data}
          isLoading={isLoading}
          getCasesListData={getCasesListData}
          handleViewOnClick={handleViewOnClick}
          handleEditOnClick={handleEditOnClick}
          deleteCase={deleteCase}
          incedentPending={incedentPending}
          setIncedentPending={setIncedentPending}
        />
      ) : (
        navigate(ACCESS_DENIED)
      )}

      <ToastContainer />
    </>
  );
};

export default CasesContainer;
