import {
  Edit,
  FileDownload,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";
import React from "react";
import DownloadCsv from "../../download_file/DownloadCsv";
import commonImages from "../../../assets";

export default function ReportRowManipulte(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dowloadCsv = React.useRef();
  const {
    rows: { row },
    viewOnClick,
    editOnClick,
    setOnClick,
    deleteOnClick,
    cloneOnClick,
    editInspectionOnClick,
    edit,
    set,
    download,
    view,
    deleted,
    credentialLists = false,
    clone = false,
    editInspection = false,
  } = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="setting-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="setting_button_ui">
          <div className="dropdownList_ui">
            <ul>
              {view && (
                <li onClick={() => viewOnClick(row)}>
                  {credentialLists ? (
                    <>
                      <Edit />
                      <span>Edit Credential</span>
                    </>
                  ) : (
                    <>
                      <RemoveRedEye />{" "}
                      <span>
                        {props?.viewLabel ? props?.viewLabel : "View Detail"}
                      </span>
                    </>
                  )}
                </li>
              )}

              {edit && (
                <li onClick={() => editOnClick(row)}>
                  <Edit /> <span>{props.editLabel}</span>
                </li>
              )}
              {set && (
                <li onClick={() => setOnClick(row)}>
                  <img
                    src={commonImages.setControlIcon}
                    className="deleteImg"
                    alt=""
                  />{" "}
                  <span>{props.setLabel}</span>
                </li>
              )}
              {download && (
                <li onClick={() => dowloadCsv.current.link.click()}>
                  <FileDownload /> <span>{props.downloadLabel}</span>
                </li>
              )}
              {deleted && (
                <li onClick={() => deleteOnClick(row)} className="deleteIcon">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.83331 15.5C2.37498 15.5 1.98276 15.3369 1.65665 15.0108C1.32998 14.6842 1.16665 14.2917 1.16665 13.8333V3H0.333313V1.33333H4.49998V0.5H9.49998V1.33333H13.6666V3H12.8333V13.8333C12.8333 14.2917 12.6703 14.6842 12.3441 15.0108C12.0175 15.3369 11.625 15.5 11.1666 15.5H2.83331ZM4.49998 12.1667H6.16665V4.66667H4.49998V12.1667ZM7.83331 12.1667H9.49998V4.66667H7.83331V12.1667Z"
                      fill="#1B436E"
                    />
                  </svg>
                  <span>{props.deleteLabel}</span>
                </li>
              )}
              {editInspection && (
                <li onClick={() => editInspectionOnClick(row)}>
                  <Edit /> <span>{props.editInsectionLabel}</span>
                </li>
              )}
              {clone && (
                <li onClick={() => cloneOnClick(row)} className="cloneIcon">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H11V16H1.5ZM4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734375 3.44062 0.440625C3.73438 0.146875 4.0875 0 4.5 0H12.5C12.9125 0 13.2656 0.146875 13.5594 0.440625C13.8531 0.734375 14 1.0875 14 1.5V11.5C14 11.9125 13.8531 12.2656 13.5594 12.5594C13.2656 12.8531 12.9125 13 12.5 13H4.5Z"
                      fill="#1B436E"
                    />
                  </svg>
                  <span>{props?.cloneLabel}</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Menu>
      <DownloadCsv
        ref={dowloadCsv}
        data={[row]}
        filename={"list-of-incident.csv"}
      />
    </>
  );
}
